<template>
    <v-app>
        <v-dialog v-model="showDialog" max-width="1000px">
            <v-card v-if="showDialog">
                <v-card-title>
                    <span>Pedido #{{orden_id}}</span>
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col">
                            <v-select
                                v-model="document_type"
                                :items="documentTypesList"
                                :rules="[v => !!v || 'Debes elegir un tipo de documento']"
                                label="Tipo de documento"
                                required
                            ></v-select>
                        </div>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="showDialog = false" >
                        Cancelar
                    </v-btn>
                    <v-btn color="success" text @click="sendForm" >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            orden_id: undefined,
            store_id: undefined,
            document_type: undefined,
            orderStatesList: []
        }
    },
    created(){        
        this.$parent.$on('openDialogGenerarDte', (datos) => {
            console.log("on.openDialogGenerarDte", datos);
            this.orden_id = datos.order_id;
            this.store_id = datos.store_id;
            this.document_type = null;
            this.fetchData();                
        });
    },
    methods: {
        sendForm(){
            var vm = this;
            this.axios({
                url: "orders/generar_dte/type",
                method: "POST",
                data: {
                    order_id: this.orden_id,
                    document_type: this.document_type
                },
            })
            .then((response) => {
                vm.showDialog = false;
                console.log("then.response s sdasa sa das d", response);
                if (response.data.invoice_url != null && response.data.invoice_url != "") {
                    vm.$emit('success', "DTE generado correctamente");
                }else{
                    vm.$emit('danger', "Error al generar el DTE");
                }
            })
            .catch((error) => {
                console.log(error);
            });
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'invoices/document_types'
            }).then( response => {
                vm.documentTypesList = response.data;
                vm.showDialog = true; 
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>